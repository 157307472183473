import {defineStore} from 'pinia'
import {AXIOS} from "../../http-commons";

export const usePayers = defineStore('payersStore', {
  id: 'payers',
  state: () => ({
    payers: [],
    loading: false
  }),
  actions: {
    addPayer(payer) {
      this.payers.unshift(payer);
      this.payers.sort((a, b) => (a.modifiedTime > b.modifiedTime) ? -1 : 1);
    },
    patchPayer(payer) {
      let elIndex = this.payers.findIndex((elem => elem.payerId === payer.payerId));
      this.payers[elIndex] = payer;
      this.payers.sort((a, b) => (a.modifiedTime > b.modifiedTime) ? -1 : 1);
    },
    deletePayer(payerId) {
      let elIndex = this.payers.findIndex((elem => elem.payerId === payerId));
      this.payers.splice(elIndex, 1);
    },
    deleteMultiplePayers(payerList) {
      for (let i = 0; i < payerList.length; i++) {
        let elIndex = this.payers.findIndex((elem => elem.payerId === payerList[i].payerId));
        this.payers.splice(elIndex, 1);
      }
    },
    populatePayers() {
      let userId = JSON.parse(localStorage.getItem('user')).id
      let params = new URLSearchParams();
      params.append('userId', userId)
      AXIOS.get('/api/getAllPayers', {params})
        .then(response => {
          this.payers = response.data;
          this.payers.sort((a, b) => (a.modifiedTime > b.modifiedTime) ? -1 : 1);
        })
    }
  }
})
