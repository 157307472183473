<template>
  <v-container fluid>
    <base-material-card color="primary" class="px-5 py-3">
      <template v-slot:heading>
        <v-row class="ma-auto">
          <v-col md="6" sm="8">
            <v-icon left color="white">mdi-account-multiple</v-icon>
            {{$t('tables.all-payers')}}
            <v-badge offset-x="-5" offset-y="-5" color="blue"
                     :content="payers.length?payers.length:'0'"></v-badge>
          </v-col>
          <v-col md="6" sm="4">
            <CreateObjectButton :object="object"></CreateObjectButton>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </template>

      <v-card-title>
        <v-row class="ma-auto">
          <v-col cols="12" md="4" sm="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-spacer></v-spacer>
      <v-data-table
        single-expand
        item-key="payerId"
        :loading="loading"
        multi-sort
        :headers="headers"
        :items="payers"
        :search="search"
        show-expand
        :expanded.sync="expanded"
        :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                itemsPerPageOptions: [ 5, 10, 15, 50, -1]
              }">
        <template v-slot:item.payerName="{ item }">
          <router-link :to="{ path: '/payer/' + item.payerId}" class="no-underline-link">
            {{item.payerName}}
          </router-link>
        </template>
        <template v-slot:item.agency.agencyName="{ item }">
          <router-link :to="{ path: '/agency/' + item.agency.agencyId}" class="no-underline-link">
            {{item.agency.agencyName}}
          </router-link>
        </template>
        <template v-slot:item.createdTime="{ item }">
          <span :key="parseTime(item.createdTime)">{{parseTime(item.createdTime)}}</span>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length/2">
            <v-simple-table dense>
              <tr>
                <td>
                  {{$t('table-expand.created-date')}}:
                </td>
                <td>
                  <h5>{{ parseTime(item.createdTime) }}</h5>
                </td>
                <td>
                  {{$t('table-expand.modified-date')}}:
                </td>
                <td>
                  <h5>{{ parseTime(item.modifiedTime) }}</h5>
                </td>
              </tr>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import CreateObjectButton from "../../components/buttons/CreateObjectButton";
  import {mapState} from 'pinia'
  import {usePayers} from '../../stores/payersStore'

  let moment = require('moment');

  export default {
    name: "Payers",
    components: {CreateObjectButton},
    data() {
      return {
        expanded: [],
        object: 'Payer',
        search: '',
        headers: [
          {
            text: this.$t('tables.full-name'),
            align: 'start',
            sortable: true,
            value: 'payerName',
            class: "primary--text",
          },
          {text: this.$t('tables.agency'), value: 'agency.agencyName', class: "primary--text"},
          {text: this.$t('tables.comment'), value: 'comment', class: "primary--text"}
        ],
      }
    },
    methods: {
      updateNeededCheck() {
        if (!this.payers.length) {
          const payersStore = usePayers()
          payersStore.populatePayers()
        }
      },
      parseTime(time) {
        moment.updateLocale(moment.locale(), {invalidDate: "Empty date"})
        return moment(time).format("DD.MM.YYYY HH:mm");
      },
    },
    computed: {
      ...mapState(usePayers, ['payers','loading'])
    },
    created() {
      this.updateNeededCheck();
    },
  }
</script>

<style scoped>
  .no-underline-link {
    text-decoration: none;
  }
</style>
