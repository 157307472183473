var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-row',{staticClass:"ma-auto"},[_c('v-col',{attrs:{"md":"6","sm":"8"}},[_c('v-icon',{attrs:{"left":"","color":"white"}},[_vm._v("mdi-account-multiple")]),_vm._v(" "+_vm._s(_vm.$t('tables.all-payers'))+" "),_c('v-badge',{attrs:{"offset-x":"-5","offset-y":"-5","color":"blue","content":_vm.payers.length?_vm.payers.length:'0'}})],1),_c('v-col',{attrs:{"md":"6","sm":"4"}},[_c('CreateObjectButton',{attrs:{"object":_vm.object}})],1)],1),_c('v-divider')]},proxy:true}])},[_c('v-card-title',[_c('v-row',{staticClass:"ma-auto"},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-spacer'),_c('v-data-table',{attrs:{"single-expand":"","item-key":"payerId","loading":_vm.loading,"multi-sort":"","headers":_vm.headers,"items":_vm.payers,"search":_vm.search,"show-expand":"","expanded":_vm.expanded,"footer-props":{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              itemsPerPageOptions: [ 5, 10, 15, 50, -1]
            }},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.payerName",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"no-underline-link",attrs:{"to":{ path: '/payer/' + item.payerId}}},[_vm._v(" "+_vm._s(item.payerName)+" ")])]}},{key:"item.agency.agencyName",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{staticClass:"no-underline-link",attrs:{"to":{ path: '/agency/' + item.agency.agencyId}}},[_vm._v(" "+_vm._s(item.agency.agencyName)+" ")])]}},{key:"item.createdTime",fn:function(ref){
            var item = ref.item;
return [_c('span',{key:_vm.parseTime(item.createdTime)},[_vm._v(_vm._s(_vm.parseTime(item.createdTime)))])]}},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length/2}},[_c('v-simple-table',{attrs:{"dense":""}},[_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.$t('table-expand.created-date'))+": ")]),_c('td',[_c('h5',[_vm._v(_vm._s(_vm.parseTime(item.createdTime)))])]),_c('td',[_vm._v(" "+_vm._s(_vm.$t('table-expand.modified-date'))+": ")]),_c('td',[_c('h5',[_vm._v(_vm._s(_vm.parseTime(item.modifiedTime)))])])])])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }