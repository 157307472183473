<template>
  <v-btn small color="white" outlined style="float: right" @click="$router.push({ name: `Create ${object}` })">
    <v-icon>
      mdi-plus
    </v-icon>
  </v-btn>
</template>

<script>
  export default {
    props: ['object'],
    name: "CreateObjectButton"
  }
</script>
